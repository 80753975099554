"use client";
import {
  Box,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import FooterLists from "./Common/FooterLists";
import { footerList } from "@/utils/FooterData";
import { socialLink } from "@/utils/socialLink";
import useWebsiteInfoStore from "@/utils/store/WebsiteInfoStore";

export default function Footer() {
  const [origin, setOrigin] = useState();
  const [host, setHost] = useState();
  const { websiteInfoData } = useWebsiteInfoStore();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setOrigin(window.location.origin);
      setHost(window.location.hostname)
    }
  }, []);
  return (
    <Box
      sx={{ p: { lg: "130px 115px", xs: "40px" }, backgroundColor: "#063755" }}
    >
      <Grid container>
        <Grid lg={6} xs={12} item={true}>
          <Link href={origin}>
            {websiteInfoData ? (
              <Image
                src={websiteInfoData?.websiteLogoUrl}
                alt="logo"
                loading="lazy"
                width={80}
                height={80}
              />
            ) : (
              <Image
                src="/assets/Logo/Logo.svg"
                alt="logo"
                loading="lazy"
                width={80}
                height={80}
              />
            )}
          </Link>
          <Typography
            variant="body1"
            sx={{
              fontSize: { lg: "24px", xs: "20px" },
              fontWeight: "600",
              color: "#fff",
              my: "26px",
              ml: { xs: "10px" },
              width: { lg: "70%", xs: "100%" },
            }}
          >
            {websiteInfoData
              ? websiteInfoData?.websiteFooterMessage
              : "eBrainee LMS: Your Premier Resource for Team Learning and Development"}
          </Typography>
          {/* <Box>
            <TextField
              label="Footer_submit_field"
              variant="outlined"
              sx={{
                background: "#fff",
                borderRadius: "5px",
                "& .MuiOutlinedInput-input": {
                  height: "0.95rem",
                  width: { lg: "300px", xs: "150px" },
                },
              }}
            />
            <Button sx={{ mx: "10px", p: "10px 28px" }}>Submit</Button>
          </Box> */}
        </Grid>
        <Grid lg={6} xs={12} item={true}>
          <Box
            sx={{
              display: "flex",
              gap: { lg: "70px", xs: "90px" },
              flexWrap: "wrap",
              mt: { lg: "0px", xs: "0px" },
            }}
          >
            <FooterLists dataList={footerList?.Product} title="Product" />
            <FooterLists dataList={footerList?.Company} title="Company" />
            {host &&
              (host?.includes("www.ebrainee.com") ||
                host?.includes("www.qaebe.online") ||
                host?.includes("www.localhost")) && (<FooterLists dataList={socialLink} title="Social" />)}
            <FooterLists dataList={footerList?.Legal} title="Legal" />
          </Box>
        </Grid>
      </Grid>
      {host &&
        (host?.includes("www.ebrainee.com") ||
          host?.includes("www.qaebe.online") ||
          host?.includes("www.localhost")) && (
          <Box
            sx={{
              display: "flex",
              gap: "0px",
              flexDirection: "row",
              justifyContent: { lg: "center", xs: "start" },
              mt: { lg: "100px", xs: "40px" },
            }}
          >
            <List
              sx={{
                display: "flex",
                gap: { lg: "20px", xs: "5px" },
              }}
            >
              {socialLink.map((item) => (
                <ListItem
                  key={item.id}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                >
                  <a
                    href={item.url}
                    style={{
                      paddingRight: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src={item.icon2}
                      width={35}
                      height={35}
                      alt={item.name}
                    />
                  </a>
                </ListItem>
              ))}
            </List>
          </Box>)}
    </Box>
  );
}
