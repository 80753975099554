// "use server";
const API = process.env.NEXT_PUBLIC_API_URL;
import getHeaders from "./APIHeaders";

export async function getAllBlogData(data, hostname) {
  const headers = await getHeaders(hostname);

  const page = data ? data : 0;

  const res = await fetch(API + `/blog?page=${page}&size=10`, {
    headers,
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return res;
}

export async function getAllBlog(hostname) {
  const headers = await getHeaders(hostname);

  const res = await fetch(API + `/blog?page=0`, {
    headers,
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return res;
}
