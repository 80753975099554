import { Link, List, ListItem } from "@mui/material";
import React from "react";

export default function FooterLists({ dataList, title }) {
  const renderTextWithLineBreaks = (text) => {
    return text.split(" ").map((word, index, array) => (
      <React.Fragment key={index}>
        {word}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <List sx={{ background: "transparent" }}>
      <ListItem
        sx={{
          color: "#98A2B3",
          fontWeight: 600,
          fontSize: "14px",
          background: "transparent",
        }}
      >
        {title}
      </ListItem>
      {dataList?.map((lists, index) => {
        return (
          <ListItem
            key={index}
            sx={{
              color: "#EAECF0",
              fontWeight: 500,
              cursor: "pointer",
              background: "transparent",
            }}
          >
            <Link
              href={lists.url}
              sx={{
                textDecoration: "none",
                color: "#EAECF0",
                fontSize: "16px",
              }}
            >
              {lists.title == "About Us"
                ? lists.title
                : renderTextWithLineBreaks(lists.title)}
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
}
