import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";
import styles from "../../app/page.module.css";
import thumbnail from "../../public/assets/images/thumbnail1.png";
import { slugify } from "@/utils/slugify";

export default function BlogCard({ blogData, removeDesc }) {

const blogSlug=slugify(blogData?.blogTitle);

  return (
    <Box sx={{ position: "relative" }}>
      <Grid container>
      {
            blogSlug && 
            <Link
            href={`/blog/${blogSlug}?blogId=${blogData?.blogId}`}
          >
            
        <Grid lg={6} xs={12} item={true}>
          {/* {blogData?.thumbnailImage && ( */}
         
            <Image
              alt="Blog thumbnail"
              src={
                blogData?.thumbnailImage
                  ? blogData?.thumbnailImage
                  : thumbnail
              }
              className={styles.imgThumBlog}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              width={100}
              height={100}
            />
        
          
           
          {/* )} */}
        </Grid>
        <Grid
          lg={6}
          xs={12}
          item={true}
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              mt: { lg: 0, xs: "20px" },
              ml: { lg: 3, xs: 0 },
              display: "flex",
              flexDirection: "column",
              justifyContent: { lg: "space-between" },
              height: "100%",
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{ color: "#6941C6", fontSize: "14px", fontWeight: 700 }}
              >
                {blogData?.blogCreatedDate}
              </Typography>
              <Typography
                sx={{
                  color: "#101828",
                  fontSize: "18px",
                  fontWeight: 600,
                  mt: "16px",
                }}
              >
        
                  {blogData?.blogTitle}
              
              </Typography>
       
                <Tooltip title={blogData?.metaDescription}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#667085",
                    fontSize: "16px",
                    fontWeight: 400,
                    overflow: 'hidden',
                    width:"auto",
                    mt: "10px",
                    letterSpacing: "1px",
                  }}
                >
                  {blogData?.metaDescription.slice(0, 180) }{ blogData?.metaDescription?.length>180 && "..."}
                </Typography></Tooltip>
            
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: "30px",
                mb: "10px",
              }}
            >
              <Box
                      // key={index}
           style={{
            display: "flex",
            alignItems: "center",
            color: "#DB2BBB",
            fontWeight: 600,
            justifyContent: "center",
          }}
           
          >
            Read More
          </Box>
              {/* {blogData?.blogTags
                ?.split(",")
                .slice(0, 3)
                .map((tag, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        p: "5px 10px",
                        fontWeight: 600,
                        backgroundColor:
                          index === 0
                            ? `#FDF2FA`
                            : index === 1
                            ? `#ECFDF3`
                            : `#EEF4FF`,
                        color:
                          index === 0
                            ? `#C11574`
                            : index === 1
                            ? `#027A48`
                            : `#3538CD`,
                        mr: "8px",
                      }}
                    >
                      {tag}
                    </Box>
                  );
                })} */}
            </Box>
          </Box>
        </Grid>
        </Link>}
      </Grid>
    </Box>
  );
}
