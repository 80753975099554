export const footerList = {
  Product: [
    { title: "Overview", url: "/" },
    { title: "Tutorials", url: "/courses" },
    { title: "Blog", url: "/blog" },
    { title: "Validate Certificate", url: "/validate-certificate" },
  ],
  Company: [
    { title: "About Us", url: "/about" },
    { title: "Careers", url: "/contact" },
  ],
  Social: [
    { title: "Twitter", url: "/" },
    { title: "LinkedIn", url: "/" },
    { title: "Facebook", url: "/" },
  ],
  Legal: [
    { title: "Terms", url: "/terms" },
    { title: "Privacy", url: "/privacy" },
    { title: "Policy", url: "/policy" },
    { title: "Contact", url: "/contact" },
  ],
};
