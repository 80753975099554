//"use server";
const API = process.env.NEXT_PUBLIC_API_URL;
import getHeaders from "./APIHeaders";

export async function getSingleBlogData(id, hostname) {
  const headers = await getHeaders(hostname);
  console.log("headers",headers)
  const res = await fetch(
    API + `/blog/${id}`,
    {
      headers,
    }
    ,
    //{ cache: "force-cache" }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return res;
}
