"use client";

import React, { useEffect, useState } from "react";

import dynamic from "next/dynamic";
import moment from "moment/moment";

import { Box, Button, Grid, ThemeProvider, Typography } from "@mui/material";
import { getSingleBlogData } from "../../lib/BlogAPISingleData";
import { getBlogData } from "@/lib/BlogAPIData";
import BlogCard from "@/components/Common/BlogCard";
import { Heading2, Subtitle1 } from "@/components/Common/HeadingText";
import { getAllBlog } from "@/lib/BlogAPIAllData";
import { useRouter, useSearchParams } from "next/navigation";
import ViewTextBox from "@/components/Common/ViewTextBox";
import { theme } from "@/components/theme";
import Navbar from "../Navbar";

const BlogSection = dynamic(() => import("@/components/Home/BlogSection"));

function SingleBlogDetailPage({ params }) {
  const [singleBlogData, setSingleBlogData] = useState();
  const [blogData, setBlogData] = useState();
  const [allBlogData, setAllBlogData] = useState([]);
  const [allBlogId, setAllBlogId] = useState([]);
  const searchParams = useSearchParams();

  const search = searchParams.get("blogId");
  const router = useRouter();

  const nextBlog = (bdata, nextbdata) => {
    const currentIndex = allBlogId.indexOf(String(bdata?.blogId));
    const nextIndex =
      allBlogId.length === currentIndex + 1 ? 0 : currentIndex + 1;

    const temp = nextbdata?.filter((bvalue) => {
      return bvalue?.blogId == allBlogId[nextIndex];
    });

    router.push(
      `/blog/${temp[0]?.blogTitle
        ?.replace("?", "")
        ?.replace(/\s+/g, "-")}?blogId=${allBlogId[nextIndex]}`
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      const data = await getBlogData(window.location.hostname);
      setBlogData(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      console.log("search ",search)
      console.log("window.location.hostname ",window.location.hostname)
      const data = await getSingleBlogData(search, window.location.hostname);
      console.log("data ",data)
      setSingleBlogData(data);
      const data2 = await getAllBlog(window.location.hostname);
      setAllBlogData(data2.response.blog);
      setAllBlogId(data2.response.blog.map((blog) => blog.blogId));
      // data2.response.blog.map((blog) => {
      //   allBlogId.push(blog.blogId);
      // });
    };

    fetchData();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Navbar />

      <Box sx={{ color: "#3A3A3A" }}>
        <Box
          sx={{
            m: { lg: "140px 100px",  xs: "0px 20px"},
          }}
        >
          <Box
            sx={{
              position: "relative",
              padding: { lg: "200px 80px", xs: "150px 20px" }, mb:{lg:"0px",md:"0px", xs :"-50px"},
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: singleBlogData?.response?.featuredImage
                  ? `url('${singleBlogData?.response?.featuredImage}')`
                  : "url('/assets/images/rec.png')",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50%",
                backgroundSize: 'contain',

                zIndex: 1,

                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: -1,
                },
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
              paddingTop: { lg: "20px", xs: "0px" },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: { lg: "46px", xs: "26px" },
                fontWeight: 700,

                width: { lg: "100%", xs: "100%" },
              }}
            >
              {singleBlogData?.response?.blogTitle}
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#3A3A3A",
                }}
              >
                {singleBlogData?.response?.blogCreatedDate &&
                  moment(singleBlogData?.response?.blogCreatedDate).format(
                    "LL"
                  )}
              </Typography>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: "30px",
            }}
          >
            {singleBlogData?.response?.blogTags
              ?.split(",")
              .map((tag, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      p: "5px 10px",
                      backgroundColor: `#E9326B20`,
                      color: "black",
                      mr: "8px",
                    }}
                  >
                    {tag}
                  </Box>
                );
              })}
          </Box>

          <Box
            sx={{
              mt: { lg: 4, xs: 4 },
              ml: { lg: 15, xs: 4 },
              mr: { lg: 30, xs: 4 },
             // color: "#3A3A3A",
            }}
          >
            <ViewTextBox
              value={singleBlogData?.response?.blogDescription}
            ></ViewTextBox>
          </Box>

          <Box sx={{ textAlign: "center", mt: 6 }}>
            <Button
            disabled={blogData?.length<=1?true:false}
              onClick={() => nextBlog(singleBlogData?.response, allBlogData)}
            >
              Read Next Blog
            </Button>
          </Box>
          <Grid container sx={{ mt: { lg: "100px", xs: "40px" } }}>
            <Grid item lg={12} xs={12}>
              <Heading2 text="Recommend Blogs" />
              <Subtitle1 text="Without them, We cannot acheive this!" />
            </Grid>
            {blogData?.response?.blog.map((blog, index) => {
              return (<  >  
            {
              blog?.blogId !=singleBlogData?.response?.blogId &&
              <Grid
              lg={6}
              xs={6}
              sx={{
                paddingTop: { lg: "40px", xs: "20px" },
                paddingRight: { lg: "40px", xs: "0px" },
              }}
              item={true}
              key={index}
            >
              <BlogCard blogData={blog} removeDesc={true} />
            </Grid>
            }           
                </>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default SingleBlogDetailPage;
