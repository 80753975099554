// "use server";

import getHeaders from "./APIHeaders";

const API = process.env.NEXT_PUBLIC_API_URL;

export async function getBlogData(hostname) {
  const headers = await getHeaders(hostname);

  const res = await fetch(API + `/blog?page=0&size=3`, {
    headers,
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return res;
}
